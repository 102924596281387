<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card layout-toppanel">
        <p><i  class="pi pi-fw pi-info-circle"></i> Se gestiona todos las pagos de los siguientes items del T.U.P.A.</p>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Nuevo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="open('new')"
            />
          </template>

          <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          selectionMode="single"
          dataKey="id"
          sortField="id"
          :sortOrder="1"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} items"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'id',
            'nombre',
            'abreviatura',
            'codigoPago'
           
          ]"
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column selectionMode="single" headerStyle="width: 3rem"></Column>
          <Column field="id" header="Id" :sortable="true" headerStyle="width: 3rem">
            <template #body="slotProps">
              <b>{{ slotProps.data.id }}</b>
            </template>
          </Column>
          <Column field="codigoPago" header="Codigo" :sortable="true" headerStyle="width: 4rem">
            <template #body="slotProps">
              <span class="p-column-title">Codigo</span>
              <b>{{ slotProps.data.codigoPago }}</b>
            </template>
          </Column>
         
           <Column field="dependencia" header="Dependencia" :sortable="true" headerStyle="width: 4rem">
            <template #body="slotProps">
              <span class="p-column-title">Dependencia</span>
              <b>{{ slotProps.data.tupa.ejecutora.abreviatura }}</b>
            </template>
          </Column>
          <Column
            field="nombre"
            header="Denominación"
            :sortable="true"
          >
          <template #body="slotProps">
              <span class="p-column-title">Denominación</span>
              {{slotProps.data.tupa.nombre}}
            </template>
          </Column>
          <Column headerStyle="width: 3rem">
            <template #body="slotProps">
              <Button
                icon="pi pi-ellipsis-v"
                @click="[itemClick(slotProps.data), toggle($event)]"
              />
              <Menu ref="menu" :model="items" :popup="true">
                <template #item="{ item }">
                  <Menuitem :rel="slotProps">
                    <li>{{ item.label }}</li>
                  </Menuitem>
                </template>
              </Menu>
            </template>
          </Column>
        </DataTable>

        

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmar"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="selectedProducts"
              >Está seguro de querer borrar el item:
              <b>{{ selectedProducts.id }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Si"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {

  data() {
    return {
      page: 1,
      numPages: 0,
      errors: [],
      scale: "page-width",
      items: [
        {
          label: "Opciones",
          items: [
           
            {
              label: "Editar",
              icon: "pi pi-fw pi-pencil",
              command: () => {
                this.editProduct(this.selectedProducts);
              },
            },
 
            {
              label: "Borrar",
              icon: "pi pi-fw pi-trash",
              command: () => {
                this.confirmDeleteProduct()
              },
            },
          ],
        },
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      product: {},
      selectedProducts: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nombreObra: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      isBusy: false,
      submitted: false,
      action: "gstupa/PAGO_SAVE",
    };
  },
  
  methods: {
    itemClick(data) {
      this.selectedProducts = data;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    myUploader(file) {
      /// console.info(file.files[0]);

      this.$swal({
        width: 370,
        text: "Subiendo archivo esperer porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/FILE_UPLOAD", file)
            .then((res) => {
              this.$swal.close();
              this.filetitulo = res.titulo;
              this.filetype = "[" + file.files[0].type + "]";
              this.product.tipoArchivo = file.files[0].type;
              this.product.ruta = res.destino;
            })
            .catch((error) => {
              this.$swal.close();
              this.fileTitulo = "error... " + error;
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    saveProduct() {
      this.submitted = true;
      let modelo = this.product;
      
       if (
        this.product.nombre && this.product.abreviatura) {
           this.isBusy = true;
           this.$swal({
          width: 370,
          text: "Cargando...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch(this.action, modelo)
              .then((res) => {
                this.productDialog = false;
                this.isBusy = false;
                this.$swal.close();
                this.listarPago();
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Pago Actualizado id: " + res.id,
                  life: 3000,
                });
              })
              .catch((error) => {
                this.$swal.close();
                this.isBusy = false;
                this.error(error)
              });
          }}).then((result) => {
          console.log(result);
        });
        }
    },
    listarPago() {
      let params = {
      };
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/PAGO_LIST", params)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = res === undefined ? [] : res;
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
  
    error(evt) {
      if (evt.response.status == 401 || evt.response.status == 500) {
        this.$swal(
          evt.response.data.errorMessage,
          "Ud. no tiene permitido esta función",
          "warning"
        );
      }
    },
     open(operacion) {
      if (operacion == "new") {
        this.product = {
          tupa : {},
          estado: true,
          detalle:[]
        };
      }
      let mutacion = {
        tupa: this.product,
        oper: operacion,
      };
      this.$store.commit("gstupa/OPERACION_TUPA", mutacion);
      this.$router.push({
        name: "tupa/pago/" + operacion,
      });
    },

    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
   
    
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.product = this.selectedProducts;
      this.deleteProductDialog = false;
      this.isBusy = true;

      this.$swal({
        width: 370,
        text: "Borrando...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gstupa/PAGO_DELETE", this.product.id)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              /*this.products = this.products.filter(
                (val) => val.id !== this.product.id
              );
              this.product = {};*/
              this.listarPago();

              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Pago borrada, numero: " + res.numero,
                life: 3000,
              });
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    editProduct(product) {
      this.action = "gstupa/PAGO_UPDATE";
      this.product = { ...product };
      this.productDialog = true;
    },
  },
  computed: {
    ...mapGetters({
      pago: "gstupa/getModelPago",
      user: "gsdialogoauth/getUser",
    }),
  },

  mounted() {
   
    this.listarPago();

  }
};
</script>

<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="css" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Page content */
.content {
  padding: 16px;
}
</style>

